<template>
   <v-app id="inspire" class="theme--light">
     <v-main>
       <v-container class="fill-height mb-2 d-flex justify-center" fluid>
         <div class="text-center">
           <div style="text-align: center">
             <v-img src="/assets/img/logo.png" class="auto__width"></v-img>
           </div>
           <v-overlay :value="axiosIsLoading" opacity=".4">
             <v-progress-circular indeterminate size="64"></v-progress-circular>
             <div
               class="mt-5 text-larger"
               v-html="$t('loader.please-wait-the-link-processor-is-working')"
             ></div>
           </v-overlay>
           <v-btn outlined color="primary darken-1" @click="backToDashboard">
          <v-icon class="mr-2">{{ iconDashboard }}</v-icon>
          {{ $t("button.back to dashboard") }}
        </v-btn>
         </div>
       </v-container>
     </v-main>
   </v-app>
 </template>
 <script>
 import { http } from "@/http/http";
 import {mdiViewDashboardVariant} from "@mdi/js";
 export default {
   name: "global-link-resolver",
   data: () => ({
      iconDashboard: mdiViewDashboardVariant,
     linkresult: undefined,
     axiosIsLoading: true,
   }),
   created() {
    //  setTimeout(() => {
    //    return this.getUrl(this.$route.params.id);
    //  }, 1000);
   },
   mounted(){
    setTimeout(() => {
      return this.getUrl(this.$route.params.mode, this.$route.params.id);
    }, 500);
    // console.log(this.$route.params.mode);
    // console.log(this.$route.params.id);
   },
   methods: {
    async getUrl(mode,id) {
      if (!id || !mode) return;
      let pageName = '';
      let resData = null;
      let paramsId = '';
      try {
         this.axiosIsLoading = true;
         const response = await http.get(`/link/resolve?mode=${mode}&id=${id}`);
         
         if (response.status !== "success") return (this.axiosIsLoading = false);
         
         resData = response.data;
         paramsId = resData.id;
         switch (resData.mode) {
            case 'revision':
               pageName = 'revision.detail'
               break;
            case 'sent':
               if (resData.scope === 'creator') {
                  pageName = 'sent.detailmac'
               } else if (resData.scope === 'reviser') {
                  pageName = 'sent.detailmar'
               }
               break;
            case 'sentx':
               if (resData.scope === 'creator') {
                  pageName = 'sentx.detailmac'
               } else if (resData.scope === 'reviser') {
                  pageName = 'sentx.detailmar'
               }
               break;
            case 'revocation':
               pageName = 'revoked.detail'
               paramsId = resData.memo_id;
               break;
            case 'termination':
               pageName = 'terminated.detail';
               paramsId = resData.memo_id;
               break;
            case 'memo':
               pageName = 'inbox.detail';
               paramsId = resData.memo_id;
               break;
            case 'masuk':
               pageName = 'incoming.detail';
               paramsId = resData.memo_id;
               break;
            case 'izin-prinsip':
               pageName = 'inbox.permit-detail';
               paramsId = resData.memo_id;
               break;
            case 'disposition':
               pageName = 'disposition-detail';
               paramsId = resData.id;
               break;
            default:
               pageName = 'revision'
               break;
         }
         
         this.$router.replace({
            name: pageName,
            params: { id: paramsId },
         });

      } catch (error) {
         this.axiosIsLoading = false;
        }
        finally {
         this.axiosIsLoading = false;
      }
      
    },
    backToDashboard(){
      this.$router.replace({ name: "dashboard", });
    }
    /*
     getUrl(mode,id) {
       if (!id || !mode) return;
       return new Promise((resolve) => {
         this.axiosIsLoading = true;
         http.get(`/link/resolve?id=${id}`).then((response) => {
           if (response.status != 200) return (this.axiosIsLoading = false);
           let result = response.data;
           if (result.memo_detail)
             this.$router.replace({ name: "inbox.detail", params: { id: id } });
           else if (result.disposition_detail && !result.revision_detail)
             this.$router.replace({
               name: "disposition-detail",
               params: { id: result.disposition_detail },
             });
           else if (result.revision_detail && !result.disposition_detail)
             this.$router.replace({
               name: "sent.detailmar",
               params: { id: id },
             });
           else if (result.revision_detail && result.disposition_detail) {
             //user sebagai penerima disposisi sekaligus terlibat dalam pembuatan memo
             //biasanya pada memo izin prinsip atau memo BOM yang didisposisikan kembali ke pengirimnya
             let route = this.$router.resolve({ path: `/memo/sent/${id}/mar/` });
             window.open(route.href, "_blank");
             this.$router.replace({
               name: "disposition-detail",
               params: { id: result.disposition_detail },
             });
           } else {
             this.$router.replace({
               name: "dashboard",
             });
             setTimeout(() => {
               this.$dialog.notify.warning(this.$t("error.data-not-found"), {
                 position: "top-left",
                 timeout: 5000,
               });
             }, 1000);
           }
           return resolve(response);
         });
       });
     },
     */
   },
 };
 </script>
 <style>
 .auto__width {
   margin: 0 auto;
   max-width: 75% !important;
 }
 </style>