import { getField, updateField } from 'vuex-map-fields';

const state = {
  controlValue: {
    showLayout: false,
    floatLayout: true,
    enableDownload: false,
    previewModal: true,
    paginateElementsByHeight: 1100,
    manualPagination: true,
    filename: 'export.pdf',
    pdfQuality: 2,
    pdfFormat: 'a4',
    pdfOrientation: 'portrait',
    pdfContentWidth: '210mm'
  }
};
const getters = {
  getField
};

const mutations = {
  updateField
};

const actions = {
  setControlValue({ commit }, payload) {
    commit('SET_CONTROL_VALUE', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}