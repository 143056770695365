<template>
  <v-app :class="`${
      !$vuetify.breakpoint.smAndDown
        ? 'full-sidebar'
        : 'mini-sidebar narrow-card'
    }`">
    <router-view />
    <portal-target name="portaldestination">
    </portal-target>
  </v-app>
</template>

<script>
import {
  mdiAlertDecagram,
  mdiClose,
  mdiInformationVariant,
  mdiRefresh,
} from "@mdi/js";
import { mapState, mapActions, mapMutations } from "vuex";
import { appName } from "./constants/config";
const { detect } = require("detect-browser");
const browser = detect();

export default {
  name: "App",
  components: {},
  data: () => ({
    valueToWatch: undefined,
    themeToWatch: undefined,
    dataCounting: null,
  }),
  mounted() {
    console.info(
      "Powered by ⚡ PTPN IV"
    );

    if (localStorage.ACTIVE_NIK) {
      this.valueToWatch = localStorage.ACTIVE_NIK;
    }
    if (localStorage.currentThemeIsDark) {
      this.themeToWatch = localStorage.currentThemeIsDark;
    }
    window.addEventListener("storage", this.onStorageUpdate);

    if (this.currentUser) {
      // this.getCounting();
    }

    this.setDeviceIsMobile(this.deviceIsMobile());
    if (browser && browser.name && this.dontRemindBrowser !== true) {
      if (browser.name === "chrome" || browser.name === "edge-chromium") return;
      this.$dialog
        .info({
          text: this.$t("msgbox.for-maximum-ux-please-use-chrome"),
          title: this.$t("msgbox.please-use-chrome-edge"),
          persistent: true,
          actions: [
            {
              text: this.$t("button.dont-remind-me"),
              color: "red",
              key: true,
              icon: mdiClose,
            },
            {
              text: this.$t("button.remind-me-later"),
              color: "primary",
              key: false,
              icon: mdiAlertDecagram,
            },
          ],
        })
        .then((result) => {
          if (result !== true) return;
          this.setDontRemindBrowser(result);
        });
    }
  },
  beforeDestroy() {
    clearInterval(this.dataCounting);
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  watch: {
    "$vuetify.breakpoint.smAndDown": {
      handler: function (newVal) {
        if (newVal == false) {
          this.setExpandOnHover(false);
        }
      },
      deep: true,
    },
    networkError(val) {
      if (!val) return;
      this.$dialog.notify.error(this.$t("error.network-error"), {
        position: "top-left",
        timeout: 15000,
      });
      return this.setNetworkError(null);
    },
    pleaseRefresh(val) {
      if (!val) return;
      this.$dialog
        .confirm({
          text: this.$t("msgbox.new-content-is-available-please-refresh"),
          title: this.$t("msgbox.reload-app"),
          persistent: true,
          icon: mdiInformationVariant,
          color: "primary",
          actions: [
            {
              text: this.$t("button.reload"),
              color: "primary",
              key: true,
              icon: mdiRefresh,
            },
          ],
        })
        .then((result) => {
          if (result != true) return;
          this.setPleaseRefresh(false);
          window.location.reload();
        });
    },
    backendError(val) {
      if (!val) return;
      let errMsg = this.$t("error.unknown");

      switch (parseInt(val.status)) {
        case 400:
          errMsg = this.$t("error.error-400-please-contact-administrator");
          break;
        case 403:
          //   if (val.message) {
          //     errMsg = val.message.message;
          //   }
          //   else {
          //     errMsg = this.$t("error.error-403-please-contact-administrator");
          //   }
          errMsg = undefined;
          break;
        case 404:
          if (val.message.data) {
            errMsg = this.$t("error." + val.message.data);
          } else {
            errMsg = this.$t("error.error-404-please-contact-administrator");
          }
          break;
        case 405:
          errMsg = this.$t("error.error-405-please-contact-administrator");
          break;
        case 422:
          errMsg = this.$t("error.some-variable-is-missing");
          break;
        case 429:
          errMsg = this.$t("error.error-405-too-many-request");
          break;
        case 419:
          errMsg = this.$t("error.error-419-trying-to-hack");
          break;
        case 500:
          if (val && val.message.message) {
            var sqlError = val.message.message.search("SQLSTATE");
            if (sqlError >= 0) {
              errMsg = this.$t("error.sql-timed-out");
            } else {
              let outputstr = val.message.message.replace(/'/g, "");
              errMsg = this.$t("error." + outputstr);
            }
          } else if (val && val.message && !val.message.data) {
            errMsg = this.$t("error." + val.message);
          } else if (val && val.message && val.message.data) {
            errMsg = this.$t("error." + val.message.data);
          } else {
            errMsg = this.$t("error.error-500-please-contact-administrator");
          }
          break;

        default:
          errMsg = this.$t("error.unknown");
          break;
      }
      if (errMsg) {
        this.$dialog.notify.error(errMsg, {
          position: "top-left",
          timeout: 15000,
        });
      }
      return this.setBackendError(null);
    },
    valueToWatch(val, oldVal) {
      if (val && !oldVal) {
        return this.$router.push(window.location.pathname).catch((e) => {
          console.error(e);
        });
      } else {
        return this.logout();
      }
    },
    themeToWatch(val) {
      if (val == "true") {
        this.setThemeDark(true);
      }
    },
    $route(to) {
      document.title = this.$t(to.meta.title) + "-" + appName || appName;
    },
  },
  computed: {
    ...mapState([
      "currentThemeIsDark",
      "networkError",
      "backendError",
      "pleaseRefresh",
    ]),
    ...mapState("user", ["dontRemindBrowser", "currentUser"]),
  },
  methods: {
    ...mapActions([
      "setDeviceIsMobile",
      "setExpandOnHover",
      "setLang",
      "setThemeDark",
      "setNetworkError",
      "setBackendError",
      "setPleaseRefresh",
    ]),
    ...mapMutations(["CHANGE_THEME_DARK"]),
    ...mapActions("user", ["logout", "setDontRemindBrowser"]),
    ...mapActions("resources", ["getDataResource"]),
    onStorageUpdate(event) {
      if (event.key === "ACTIVE_NIK") {
        this.valueToWatch = event.newValue;
      }
      if (event.key == "currentLanguage") {
        this.setLang(event.newValue);
      }
      if (event.key == "currentThemeIsDark") {
        this.setThemeDark(event.newValue == "true");
      }
    },
    getCounting() {
      this.dataCounting = setInterval(() => {
        this.getDataResource({ source: "counting", tipe:"all" });
      }, 30000);
    },
  },
};
</script>
