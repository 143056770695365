<template>
  <div class="background">
    <div class="cont_error">
      <h5>Error</h5>
      <h1>404</h1>
      <p>{{ $t("error.404-desc") }}</p>
      <router-link to="/">{{$t('button.home')}}</router-link>
    </div>
    <span v-for="(buble, id) in bubles" :key="id"></span>
  </div>
</template>

<script>
import { mdiMenu } from "@mdi/js";

export default {
  name: "error-404",
  data: () => ({
    loadAnimation: false,
    bubles: 20,
    iconHome : mdiMenu
  }),
  created() {
    this.loadAnimation = true;
  },
  mounted() {
    setTimeout(() => {
      this.loadAnimation = false;
    }, 500);
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
  overflow: hidden;
}

.background {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: #3e1e68;

  .cont_error {
    position: absolute;
    width: 100%;
    vertical-align: middle;
    height: 300px;
    margin: 0 auto;
    text-align: center;
    top: 30%;
    z-index: 1;
  }

  .cont_error {
    h1,
    h5 {
      font-weight: 400;
      color: #f2f2f2 !important;
      position: relative;
      transition: all 0.5s;
    }
    h1 {
      font-size: 10rem;
      line-height: 10rem;
    }
    h5 {
      font-size: 2.5rem;
    }
    p, a, i {
      font-weight: 300;
      font-size: 1.2rem;
      color: #f2f2f2 !important;
      position: relative;
      transition: all 0.5s;
      transition-delay: 0.5s;
      -webkit-transition: all 0.5s;
      -webkit-transition-delay: 0.5s;
    }
  }
}

$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 20;
.background span {
  width: $particleSize;
  height: $particleSize;
  border-radius: $particleSize;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: $animationDuration;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  $colors: (#583c87, #e45a84, #ffacac);
  @for $i from 1 through $amount {
    &:nth-child(#{$i}) {
      color: nth($colors, random(length($colors)));
      top: random(100) * 1%;
      left: random(100) * 1%;
      animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
      animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
      transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
      $blurRadius: (random() + 0.5) * $particleSize * 0.5;
      $x: if(random() > 0.5, -1, 1);
      box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
    }
  }
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
</style>