import { http } from "@/http/http";
import { rowPerPage } from "@/constants/config.js";
import { mdiCheck } from "@mdi/js";
import app from '@/main';

const state = {
  axiosLoading: false,
  terminatedList: undefined,
  singleTerminated: undefined,
  selectAllTerminated: false,
  singleMemoHistory: undefined,
  revisionHistory: undefined,
  revisionTracking: undefined,
  printPreviewDialog: false,
  filterParams: {
    sortBy: "datetime",
    sortByDesc: "desc",
    perPage: rowPerPage,
    currentPage: 1,
    filter: "",
    from: 1,
    to: 50,
    lastPage: 1,
    total: undefined
  }
};
const getters = {
  axiosLoading: state => state.axiosLoading,
  terminatedList: state => state.terminatedList,
  singleTerminated: state => state.singleTerminated,
  selectAllTerminated: state => state.selectAllTerminated,
  singleMemoHistory: state => state.singleMemoHistory,
  revisionHistory: state => state.revisionHistory,
  revisionTracking: state => state.revisionTracking,
  printPreviewDialog: state => state.printPreviewDialog,
  filterParams: state => state.filterParams,
};
const mutations = {
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload;
  },
  SET_TERMINATED_LIST(state, payload) {
    state.terminatedList = payload;
    state.filterParams.perPage = payload.per_page;
    state.filterParams.currentPage = payload.current_page;
    state.filterParams.lastPage = payload.last_page;
    state.filterParams.filter = "";
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_SINGLE_TERMINATED(state, payload) {
    state.singleTerminated = payload;
  },
  SET_SELECT_ALL_TERMINATED(state, payload) {
    state.selectAllTerminated = payload;
  },
  SET_SINGLE_MEMO_HISTORY(state, payload) {
    state.singleMemoHistory = payload;
  },
  SET_REVISION_HISTORY(state, payload) {
    state.revisionHistory = payload;
  },
  SET_REVISION_TRACKING(state, payload) {
    state.revisionTracking = payload;
  },
  SET_PRINT_PREVIEW_DIALOG(state, payload) {
    state.printPreviewDialog = payload;
  },
  SET_FILTER_PARAMS(state, payload) {
    state.filterParams.sortBy = payload.sortBy;
    state.filterParams.sortByDesc = payload.sortByDesc
    state.filterParams.perPage = payload.perPage;
    state.filterParams.currentPage = payload.currentPage;
    state.filterParams.lastPage = payload.lastPage;
    state.filterParams.filter = payload.filter;
    state.filterParams.only = payload.only;
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
};
const actions = {
  setAxiosLoading({ commit }, payload) {
    commit("SET_AXIOS_LOADING", payload);
  },
  loadTerminated({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/terminated?page=${payload.currentPage}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_TERMINATED_LIST', response.data)
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },

  loadTerminatedDetail({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve, reject) => {
      //cek creator nik yg aktif = payload.creator_nik
      //memo/draft/{memo_id}
      http.get(`/memo/terminated/${payload.memoId}`)
        .then((response) => {

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
            commit('SET_SINGLE_TERMINATED', response.data);
          }
          else {
            return reject('fail');
          }
          return resolve(response.data);
        })
    })
  },

  makeCopyAsNewDraft({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      //cek creator nik yg aktif = payload.creator_nik
      //memo/draft/{memo_id}
      http.post(`/memo/terminated/${payload.memoId}/copy-new-draft`)
        .then((response) => {
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          if (response && (response.status == 200 || response.status == 'success')) {
            app.$dialog.info({
              text: app.$t("msgbox.copy-as-new-draft-is-success"),
              title: app.$t("msgbox.copy-as-new-draft"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
          }
          else {
            app.$dialog.error({
              text: app.$t("error.copy-as-new-draft-failed"),
              title: app.$t("msgbox.copy-as-new-draft"),
              persistent: true,
              actions: {
                false: app.$t("button.ok")
              }
            });
          }
          return resolve(response.data);
        })
    })
  },

  emptyTerminated({ commit }) {
    commit("SET_TERMINATED_LIST", undefined);
  },
  setFilterParams({ commit }, payload) {
    commit("SET_FILTER_PARAMS", payload);
  },
  setSelectAllTerminated({ commit }, payload) {
    commit("SET_SELECT_ALL_TERMINATED", payload);
  },
  setSingleTerminated({ commit }, payload) {
    commit("SET_SINGLE_TERMINATED", payload)
  },
  setSingleMemoHistory({ commit }, payload) {
    commit("SET_SINGLE_MEMO_HISTORY", payload)
  },
  setRevisionHistory({ commit }, payload) {
    commit("SET_REVISION_HISTORY", payload)
  },
  setRevisionTracking({ commit }, payload) {
    commit("SET_REVISION_TRACKING", payload)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}