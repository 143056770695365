import { http } from "@/http/http";
import { rowPerPage } from "@/constants/config.js";

const state = {
  axiosLoading: false,
  revokedList: undefined,
  singleRevoked: undefined,
  selectAllRevoked: false,
  singleMemoHistory: undefined,
  revisionHistory: undefined,
  revisionTracking: undefined,
  printPreviewDialog: false,
  filterParams: {
    sortBy: "datetime",
    sortByDesc: "desc",
    perPage: rowPerPage,
    currentPage: 1,
    filter: "",
    from: 1,
    to: 50,
    lastPage: 1,
    total: undefined
  }
};
const getters = {
  axiosLoading: state => state.axiosLoading,
  revokedList: state => state.revokedList,
  singleRevoked: state => state.singleRevoked,
  selectAllRevoked: state => state.selectAllRevoked,
  singleMemoHistory: state => state.singleMemoHistory,
  revisionHistory: state => state.revisionHistory,
  revisionTracking: state => state.revisionTracking,
  printPreviewDialog: state => state.printPreviewDialog,
  filterParams: state => state.filterParams,
};
const mutations = {
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload;
  },
  SET_REVOKED_LIST(state, payload) {
    state.revokedList = payload;
    state.filterParams.perPage = payload.per_page;
    state.filterParams.currentPage = payload.current_page;
    state.filterParams.lastPage = payload.last_page;
    state.filterParams.filter = "";
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_SINGLE_REVOKED(state, payload) {
    state.singleRevoked = payload;
  },
  SET_SELECT_ALL_REVOKED(state, payload) {
    state.selectAllRevoked = payload;
  },
  SET_REVISION_TRACKING(state, payload) {
    state.revisionTracking = payload;
  },
  SET_FILTER_PARAMS(state, payload) {
    state.filterParams = payload;
  },
};
const actions = {
  setAxiosLoading({ commit }, payload) {
    commit("SET_AXIOS_LOADING", payload);
  },
  loadRevoked({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/revoked?page=${payload.currentPage}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_REVOKED_LIST', response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },

  loadRevokedDetail({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve, reject) => {
      //cek creator nik yg aktif = payload.creator_nik
      //memo/draft/{memo_id}
      http.get(`/memo/revoked/${payload.memoId}`)
        .then((response) => {

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
            commit('SET_SINGLE_REVOKED', response.data);
          }
          else {
            return reject('fail');
          }
          return resolve(response.data);
        })
    })
  },

  // emptyRevoked({ commit }) {
  //   commit("SET_REVOKED_LIST", undefined);
  // },
  setFilterParams({ commit }, payload) {
    commit("SET_FILTER_PARAMS", payload);
  },
  setSelectAllRevoked({ commit }, payload) {
    commit("SET_SELECT_ALL_REVOKED", payload);
  },
  setSingleRevoked({ commit }, payload) {
    commit("SET_SINGLE_REVOKED", payload)
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}