import store from "@/store";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
// const kraniRevisionNumber = [18, 19, 198];
const stafRevisionNumber = [21, 22, 23, 24, 26, 27, 28, 298, 29];
const kasubRevisionNumber = [31, 32, 33, 34, 36, 37, 38, 398, 39];
const pjpRevisionNumber = [41, 410, 411, 48, 49, 498, 499];
const dirRevisionNumber = [51, 510, 511, 598, 58, 59, 599];

export default {
  methods: {
    deviceIsMobile() {
      let check = false;
      /* eslint-disable */
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      /* eslint-enable */
      return check;
    },
    _deviceType() {
      let devType = "pc";
      if (screen.width <= 414) {
        devType = "smartphone";
      } else if (screen.width <= 768) {
        devType = "tablet";
      } else {
        devType = "pc";
      }
      return devType;
    },
    strCut(text, limit) {
      if (!text || !limit) return;
      if (text.length > limit) {
        return text.substr(0, limit - 1).trim() + "...";
      }
      return text;
    },
    strCutStripped(str, limit) {
      if (!str || !limit) return;
      const regex = /(<([^>]+)>)/gi;
      let stripped = str.replace(regex, "");
      return this.strCut(stripped, limit);
    },
    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    isEventOrOdd(val) {
      let result = "odd";
      if (val % 2 == 0) {
        result = "even";
      } else if (Math.abs(val % 2) == 1) {
        result = "odd";
      }
      return result;
    },
    removeFromArray(original, remove) {
      if (!original) return [];
      if (original.length < 1) return [];
      return original.filter((value) => !remove.includes(value));
    },
    addToArray(original, newData) {
      if (!newData) return;
      return original.concat(newData);
    },
    compareArray(arr1, arr2) {
      if (arr1.length != arr2.length) {
        return false;
      } else {
        let sortArr1 = arr1.sort();
        let sortArr2 = arr2.sort();
        let result = false;
        // comparing each element of array
        for (let i = 0; i < sortArr1.length; i++) {
          if (sortArr1[i] != sortArr2[i]) {
            return false;
          } else {
            result = true;
          }
        }
        return result;
      }
    },
    similarItemExist(arr1, arr2) {
      if (!arr1 || !arr2) return false;
      if (arr1.length < 1 || arr2.length < 1) return false;
      let result = arr1.filter((e) => arr2.includes(e));
      return result.length > 0;
    },
    numToBool(val) {
      if (!val) return false;
      if (parseInt(val) === 1) return true;
      if (parseInt(val) === 0) return false;
      return false;
    },
    boolToNum(val) {
      if (val == true) {
        return 1;
      } else {
        return 0;
      }
    },
    toUpperFL(str) {
      //toUpperFirstLetter
      if (!str) return "";
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(" ");
    },
    getMyOwnTask(val, arr) {
      //untuk mengambil task pribadi dari list setara disposition_detail_recipient
      let result = arr.filter(function (e) {
        return e.nik == val;
      });
      return result;
    },
    clearFormData(data) {
      //bersihkan model dari null value dan empty string
      //untuk dikirim ke backend
      const removeEmpty = (obj) =>
        Object.keys(obj)
          .filter((f) => obj[f] != null)
          .filter((f) => obj[f] != undefined)
          .filter((f) => obj[f] != [])
          .filter((f) => obj[f] != "")
          .reduce(
            (r, i) =>
              typeof obj[i] === "object"
                ? { ...r, [i]: removeEmpty(obj[i]) } // recurse.
                : { ...r, [i]: obj[i] },
            {}
          );
      let clearData = removeEmpty(data);
      let formData = new FormData();

      for (var key in clearData) {
        if (key == "isi") {
          formData.append("isi", DOMPurify.sanitize(data.isi));
        } else if (key == "hal") {
          formData.append("hal", DOMPurify.sanitize(data.hal));
        } else if (key == "kepada" && Array.isArray(data.kepada)) {
          formData.append("kepada", data.kepada.join());
        } else if (key === "kepada_line_1") {
          formData.append("kepada_line_1", data.kepada_line_1);
        } else if (key === "kepada_line_2") {
          formData.append("kepada_line_2", data.kepada_line_2);
        } else if (key === "kepada_line_3") {
          formData.append("kepada_line_3", data.kepada_line_3);
        } else if (key === "kepada_alamat") {
          formData.append("kepada_alamat", data.kepada_alamat);
        } else if (key === "approver_x") {
          formData.append("approver_x", data.approver_x);
        } else if (key === "approver_subholding") {
          formData.append("approver_subholding", data.approver_subholding);
        } else if (key === "izin_prinsip_recipients") {
          formData.append(
            "izin_prinsip_recipients",
            data.izin_prinsip_recipients.join()
          );
        } else if (key === "tembusan") {
          formData.append("tembusan", data.tembusan.join());
        } else if (key === "share_to_revision") {
          formData.append("share_to_revision", data.share_to_revision.join());
        } else if (key === "direksi") {
          // formData.append('direksi', data.direksi['id']);
          formData.append("direksi", data.direksi);
        } else if (key === "is_bom") {
          formData.append("is_bom", this.boolToNum(data.is_bom));
        } else if (key === "is_masuk") {
          formData.append("is_masuk", this.boolToNum(data.is_masuk));
        } else if (key === "is_eksternal") {
          formData.append("is_eksternal", this.boolToNum(data.is_eksternal));
        } else if (key === "is_rahasia") {
          formData.append("is_rahasia", this.boolToNum(data.is_rahasia));
        } else if (key === "urgensi") {
          formData.append("urgensi", this.boolToNum(data.urgensi));
        } else if (key === "attachments") {
          for (const [key, file] of Object.entries(data.attachments)) {
            formData.append(`attachments[${key}]`, file.file);
          }
        } else if (key === "details") {
          formData.append("details", JSON.stringify(data.details));
        } else {
          formData.append(key, clearData[key]);
        }
      }
      return formData;
    },
    showHelpRecipient(tipNumber) {
      store.dispatch("setHelpGroupRecipientDialog", tipNumber);
    },

    draftStatus(params) {
      let result = "submitted";
      if (!params || !params.currentUserType) return result;

      if (params.hasRead == null) return "unread";

      if ([19, 29, 39, 49, 59].includes(params.memoStatus)) return "terminated";
      switch (params.currentUserType) {
        case "KARPIM":
        case "KARPIM_KANPUS":
          if (
            stafRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 2
          )
            result = "submitted";
          else if (
            params.memoStatus === 25 &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 2
          )
            result = "pending";
          else if (
            stafRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 2
          )
            result = "pending";
          else if (
            stafRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 1
          )
            result = "pending";
          else if (
            stafRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 1
          )
            result = "submitted";
          break;
        case "KASUBAG":
        case "KADIS":
          if (
            kasubRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 2
          )
            result = "submitted";
          else if (
            params.memoStatus === 35 &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 2
          )
            result = "pending";
          else if (
            kasubRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 2
          )
            result = "pending";
          else if (
            kasubRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 1
          )
            result = "pending";
          else if (
            kasubRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 1
          )
            result = "submitted";
          break;
        case "PJP":
        case "PJP_KANPUS":
          if (
            pjpRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 2
          )
            result = "submitted"; //sbg reviser BOM
          else if (
            pjpRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 2
          )
            result = "pending"; //sbg reviser BOM
          else if (
            pjpRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 3
          )
            result = "pending"; //sbg sender Biasa tapi editor disabled
          break;
        case "DIREKTUR":
          if (
            dirRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 2 &&
            params.revisionTypeId === 3
          )
            result = "submitted";
          else if (
            dirRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 2
          )
            result = "pending";
          else if (
            dirRevisionNumber.includes(params.memoStatus) &&
            params.revisionStatus === 1 &&
            params.revisionTypeId === 3
          )
            result = "pending";
          break;

        default:
          result = "submitted";
          break;
      }
      return result;

      // if ([25, 35, 45].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 2 && parseInt(params.revisionTypeId) === 2) result = 'pending';
      // else if ([25, 35, 45].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 2 && parseInt(params.revisionTypeId) === 1) result = 'submitted';
      // else if ([22, 32, 24, 34].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 1 && parseInt(params.revisionTypeId) === 1) result = 'pending';
      // else if ([22, 32, 24, 34].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 2 && parseInt(params.revisionTypeId) === 1) result = 'submitted';
      // else if ([23,33].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 1 && parseInt(params.revisionTypeId) === 1 ) result = 'pending';
      // else if ([21,31].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 1 && parseInt(params.revisionTypeId) === 1 ) result = 'locked';
      // else if ([22, 23, 32, 33, 24, 34].includes(parseInt(params.memoStatus)) && parseInt(params.revisionTypeId) === 2) result = 'locked';
      // else if ([18, 28, 38, 48].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 2) result = 'submitted';
      // else if ([23,33].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 2 && parseInt(params.revisionTypeId) === 1 ) result = 'submited';
      // else if ([21,31,41,51].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 1 && parseInt(params.revisionTypeId) === 2 ) result = 'pending';
      // else if ([22, 32, 24, 34].includes(parseInt(params.memoStatus)) && parseInt(params.revisionStatus) === 2 && parseInt(params.revisionTypeId) === 1) result = 'submitted';
      // else if ([29, 39, 49].includes(parseInt(params.memoStatus))) result = 'terminated';

      // return result;
    },
    mydate(date, scope) {
      let result = "";
      switch (scope) {
        case "dayname":
          result = dayjs(date).format("dddd");
          break;
        case "time":
          result = dayjs(date).format("HH:mm");
          break;
        case "date":
          result = dayjs(date).format("DD-MM-YYYY");
          break;
        case "ldate":
          result = dayjs(date).format("DD MMMM YYYY");
          break;

        default:
          break;
      }
      return result;
    },
    timeAgo(date) {
      if (!date) return "";
      return dayjs(date).fromNow();
    },
    indoDate(date) {
      if (!date) return this.$t("text.no-data");
      return dayjs(date).format("DD MMMM YYYY HH:mm") + " WIB";
    },
    urlBase64ToUint8Array(base64String) {
      if (!base64String) return null;

      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },

    getIPAddresses() {
      var ip = Object.values(require("os").networkInterfaces())
        .flat()
        .filter(({ family, internal }) => family === "IPv4" && !internal)
        .map(({ address }) => address);
      return ip;
    },
    truncateFileName(fileName, maxLength) {
      if (!fileName) return null;
      // Cari posisi titik terakhir dalam nama file untuk mendapatkan ekstensi file
      let dotIndex = fileName.lastIndexOf(".");
      if (dotIndex === -1) return fileName; // Jika tidak ada titik, kembalikan nama file asli

      // Pisahkan nama file dan ekstensi
      let name = fileName.substring(0, dotIndex);
      let extension = fileName.substring(dotIndex);

      // Jika panjang nama file sudah sesuai atau lebih pendek dari batas, kembalikan nama file asli
      if (name.length <= maxLength) return fileName;

      // Potong nama file dan tambahkan ...
      let truncatedName = name.substring(0, maxLength - 3) + "...";

      // Gabungkan kembali nama yang sudah dipotong dengan ekstensi
      return truncatedName + extension;
    },
    checkForNullValues(dataArray) {
      // Iterasi melalui setiap item dalam array
      for (const item of dataArray) {
        // Iterasi melalui setiap key dalam item object
        for (const key in item) {
          if (item[key] === null) {
            return false; // Keluar dari fungsi jika ada nilai 'null'
          }
        }
      }
      return true; // Semua objek tidak mengandung nilai 'null'
    },
  },
};
