import { http } from "@/http/http";
import app from '@/main';
// import { mdiCheck } from "@mdi/js";
import { rowPerPage } from "@/constants/config.js";

const state = {
  axiosLoading: false,
  units: undefined,
  bagians: undefined,
  jabatans: undefined,
  karyawan: undefined,
  roles: undefined,
  types: undefined,
  user: undefined,
  administrators: undefined,
  filterParams: {
    sortBy: "datetime",
    sortByDesc: "desc",
    perPage: rowPerPage,
    currentPage: 1,
    filter: "",
    from: 1,
    to: 50,
    lastPage: 1
  }
};
const getters = {
  axiosLoading: state => state.axiosLoading,
  units: state => state.units,
  bagians: state => state.bagians,
  jabatans: state => state.jabatans,
  karyawan: state => state.karyawan,
  roles: state => state.roles,
  types: state => state.types,
  user: state => state.user,
  administrators: state => state.administrators
};
const mutations = {
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload;
  },
  SET_UNITS(state, payload) {
    state.units = payload;
  },
  SET_BAGIANS(state, payload) {
    state.bagians = payload;
  },
  SET_JABATANS(state, payload) {
    state.jabatans = payload;
  },
  SET_KARYAWAN(state, payload) {
    state.karyawan = payload;
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SET_TYPES(state, payload) {
    state.types = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_ADMINISTRATORS(state, payload) {
    state.administrators = payload;
  }
};
const actions = {
  getAdministrators({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      http.get(`/admin/user?page=${payload.currentPage}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_ADMINISTRATORS', response.data)
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },
  addAdministrator({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      http.post(`/admin/user`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('getAdministrators', state.filterParams);

            app.$dialog.info({
              text: app.$t("msgbox.add-administrator-is-success"),
              title: app.$t("msgbox.add-administrator"),
              persistent: true,
              actions: {
                false: app.$t("button.ok")
              }
            });
            
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },
  deleteAdministrator({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      http.post(`/admin/user/${payload.adminId}`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('getAdministrators', state.filterParams);

            app.$dialog.info({
              text: app.$t("msgbox.delete-administrator-is-success"),
              title: app.$t("msgbox.delete-administrator"),
              persistent: true,
              actions: {
                false: app.$t("button.ok")
              }
            });

          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },
  setAdministrators({ commit }, payload) {
    commit('SET_ADMINISTRATORS', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}