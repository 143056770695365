import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.locale('id')
dayjs.extend(relativeTime);
dayjs.extend(updateLocale)

dayjs.updateLocale('id', {
  updateLocale: {
    future: "%s lagi",
    past: "%s lalu",
    s: 'bbrp detik lalu',
    m: "semenit lalu",
    mm: "%d menit",
    h: "sejam",
    hh: "%d jam",
    d: "sehari",
    dd: "%d hari",
    M: "sebulan",
    MM: "%d bulan",
    y: "setahun",
    yy: "%d tahun"
  }
})

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
});