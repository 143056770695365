import { http } from "@/http/http";
import app from '@/main';

const state = {
  memoRecipient: undefined,
  approverXList: undefined,
  approverSubholdingList:undefined,
  dirSevps: undefined,
  dispositionMessages: undefined,
  dispositionRecipients: undefined,
  broadcastRecipients: undefined,
  permitMessages: undefined,
  myPeers: undefined,
  myUplines: null,
  axiosLoading: false,
  myUnreadCount: undefined,
  myDashboardCount: undefined,
  units: undefined,
  bagians: undefined,
  types: undefined,
  jabatans: undefined,
  karyawans: undefined,
  roles: undefined,
  bawahanTersedia: undefined,
  bawahanTerpilih: undefined,
  penggantiTersedia: undefined,
  penggantiTerpilih: undefined,
  lembagas: undefined
};

const getters = {
  memoRecipient: state => state.memoRecipient,
  approverXList: state => state.approverXList,
  approverSubholdingList: state => state.approverSubholdingList,
  dirSevps: state => state.dirSevps,
  dispositionMessages: state => state.dispositionMessages,
  broadcastRecipients: state => state.broadcastRecipients,
  dispositionRecipients: state => state.dispositionRecipients,
  permitMessages: state => state.permitMessages,
  myPeers: state => state.myPeers,
  axiosLoading: state => state.axiosLoading,
  myUplines: state => state.myUplines,
  myUnreadCount: state => state.myUnreadCount,
  myDashboardCount: state => state.myDashboardCount
};

const mutations = {
  SET_MEMO_RECIPIENTS(state, payload) {
    state.memoRecipient = payload;
  },
  SET_APPROVER_X(state, payload) {
    state.approverXList = payload;
  },
  SET_APPROVER_SUBHOLDING(state, payload) {
    state.approverSubholdingList = payload;
  },
  SET_DIR_SEVP(state, payload) {
    if (!payload) return state.dirSevps = undefined;
    const arrDirKom = ['DIREKTUR','KOMISARIS'];
    let kelDireksi = payload.filter(item => arrDirKom.includes(item.kelompok));
    if (!kelDireksi || !kelDireksi.length > 0) state.dirSevps = undefined;
    state.dirSevps = kelDireksi;
  },
  SET_DISPOSITION_MESSAGES(state, payload) {
    state.dispositionMessages = payload;
  },
  SET_DISPOSITION_RECIPIENTS(state, payload) {
    state.dispositionRecipients = payload;
  },
  SET_BROADCAST_RECIPIENTS(state, payload) {
    state.broadcastRecipients = payload;
  },
  SET_PERMIT_MESSAGES(state, payload) {
    state.permitMessages = payload;
  },
  SET_MY_PEERS(state, payload) {
    state.myPeers = payload;
  },
  SET_MY_UPLINES(state, payload) {
    state.myUplines = payload;
  },
  SET_MY_UNREAD_COUNT(state, payload) {
    state.myUnreadCount = payload;
  },
  SET_MY_DASHBOARD_COUNT(state, payload) {
    state.myDashboardCount = payload;
  },
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload
  },
  SET_UNITS(state, payload) {
    state.units = payload;
  },
  SET_BAGIANS(state, payload) {
    state.bagians = payload;
  },
  SET_TYPES(state, payload) {
    state.types = payload;
  },
  SET_JABATANS(state, payload) {
    state.jabatans = payload;
  },
  SET_KARYAWANS(state, payload) {
    state.karyawans = payload;
  },
  SET_BAWAHAN_TERSEDIA(state, payload) {
    state.bawahanTersedia = payload;
  },
  SET_BAWAHAN_TERPILIH(state, payload) {
    state.bawahanTerpilih = payload;
  },
  SET_PENGGANTI_TERSEDIA(state, payload) {
    state.penggantiTersedia = payload;
  },
  SET_PENGGANTI_TERPILIH(state, payload) {
    state.penggantiTerpilih = payload;
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SET_LEMBAGAS(state, payload) {
    state.lembagas = payload;
  }
};
const actions = {
  resetAllResource({ commit }){
    console.log('called on logout')
    commit('SET_MEMO_RECIPIENTS',null);
    commit('SET_APPROVER_X',null);
    commit('SET_APPROVER_SUBHOLDING',null);
    commit('SET_DIR_SEVP',null);
    commit('SET_DISPOSITION_MESSAGES',null);
    commit('SET_DISPOSITION_RECIPIENTS',null);
    commit('SET_BROADCAST_RECIPIENTS',null);
    commit('SET_PERMIT_MESSAGES',null);
    commit('SET_MY_PEERS',null);
    commit('SET_MY_UPLINES',null);
    commit('SET_MY_UNREAD_COUNT',null);
    commit('SET_MY_DASHBOARD_COUNT',null);
    commit('SET_UNITS',null);
    commit('SET_BAGIANS',null);
    commit('SET_TYPES',null);
    commit('SET_JABATANS',null);
    commit('SET_KARYAWANS',null);
    commit('SET_BAWAHAN_TERSEDIA',null);
    commit('SET_BAWAHAN_TERPILIH',null);
    commit('SET_PENGGANTI_TERSEDIA',null);
    commit('SET_PENGGANTI_TERPILIH',null);
    commit('SET_ROLES',null);
    commit('SET_LEMBAGAS',null);
  },
  getDashboardCount({ commit }) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/memo/dashboard`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_MY_DASHBOARD_COUNT", response.data);
          commit('SET_AXIOS_LOADING', false);
        }
        return resolve(response.data);
      });
    });
  },
  getDataResource({ commit, rootState }, payload) {
    if (!rootState.user.currentUser) return;

    commit('SET_AXIOS_LOADING', true);

    // if (!payload.bgProcess) {
    //   commit(
    //     "INC_DEC_PROCESS",
    //     { number: 1, message: "loader.loading-data"},
    //     { root: true }
    //   );
    // }

    return new Promise(resolve => {
      // if (payload.source === 'subholding-recipients') commit("SET_MEMO_RECIPIENTS", []);
      
      let tipe  = payload.tipe;
      let param = payload.param;
      if (payload.tipe === '' || payload.tipe === undefined) tipe = '';
      if (payload.param === '' || payload.param === undefined) param = '';

      http.get(`/src/${payload.source}?tipe=${tipe}&param=${param}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          switch (payload.source) {
            case "disposition-recipients":
            case "disposition-recipient-dir":
              commit("SET_DISPOSITION_RECIPIENTS", response.data);
              break;
            case "disposition-messages":
              commit("SET_DISPOSITION_MESSAGES", response.data);
              break;
            case "izin-prinsip-messages":
              commit("SET_PERMIT_MESSAGES", response.data);
              break;
            case "broadcast-recipients":
              commit("SET_BROADCAST_RECIPIENTS", response.data);
              break;
            case "memo-recipients":
              commit("SET_MEMO_RECIPIENTS", response.data);
              commit("SET_DIR_SEVP", response.data);
              break;
            case "subholding-recipients":
            case "xnusantara-recipients":
              commit("SET_MEMO_RECIPIENTS", response.data);
              break;
            case "izin-prinsip-recipients":
              commit("SET_MEMO_RECIPIENTS", response.data);
              commit("SET_DIR_SEVP", response.data);
              break;
            case "approverxlist":
              commit("SET_APPROVER_X", response.data);
              break;
            case "approver-subholding-list":
              commit("SET_APPROVER_SUBHOLDING", response.data);
              break;
            case "peer-revisers":
              commit("SET_MY_PEERS", response.data);
              break;
            case "atasan":
              commit("SET_MY_UPLINES", response.data);
              break;
            case "counting":
              commit("SET_MY_UNREAD_COUNT", response.data);
              break;
            case "lembagas":
              commit("SET_LEMBAGAS", response.data);
              break;
            default:
              break;
          }
          // if (!payload.bgProcess) {
          //   commit("INC_DEC_PROCESS", { number: -1, message: "" }, { root: true });
          // }
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });
    });
  },
  getUnits({ commit }) {
    return new Promise(resolve => {
      commit('SET_AXIOS_LOADING', true);
      http.get(`/admin/src/units`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_UNITS", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });

    });
  },
  getBagians({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/admin/src/bagians?unit_id=${payload.unitId}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_BAGIANS", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });

    });
  },
  getTypes({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/admin/src/types?unit_id=${payload.unitId}&bagian_id=${payload.bagianId}&without=${payload.without}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_TYPES", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });

    });
  },
  getKaryawans({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/admin/src/karyawan?unit_id=${payload.unitId}&bagian_id=${payload.bagianId}&type=${payload.type}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_KARYAWANS", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });

    });
  },

  getBawahanTerpilih({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/admin/src/bawahan-terpilih?atasan_nik=${payload.nikAtasan}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_BAWAHAN_TERPILIH", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });
    });
  },

  getPenggantiTerpilih({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/admin/src/pengganti-terpilih?pjt_nik=${payload.nikPjt}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_PENGGANTI_TERPILIH", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });
    });
  },

  getBawahanTersedia({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/admin/src/bawahan-tersedia?unit_id=${payload.kodeUnit}&bagian_id=${payload.kodeBagian}&level_aplikasi_elemen=${payload.userTypeElemen}&atasan_nik=${payload.nikAtasan}&bawahan_nik=${payload.nikBawahan}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_BAWAHAN_TERSEDIA", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });
    });
  },

  getPenggantiTersedia({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise(resolve => {
      http.get(`/admin/src/pengganti-tersedia?unit_id=${payload.kodeUnit}&bagian_id=${payload.kodeBagian}&level_aplikasi_elemen=${payload.userTypeElemen}&pjt_nik=${payload.nikPjt}&pengganti_nik=${payload.nikPjs}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_PENGGANTI_TERSEDIA", response.data);
        }
        commit('SET_AXIOS_LOADING', false);
        return resolve(response.data);
      });
    });
  },
  updateBawahan({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //save, submit, share-to-revision, send, broadcast
    return new Promise((resolve) => {
      //submit is send to superior
      // api/memo/{id}/disposition
      http.post(`admin/bawahan/update`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            // dispatch('getDisposition', { memoId: payload.memoId, bgProcess: true, type: 'mydelegation' });
            app.$dialog.info({
              text: app.$t("msgbox.update-subordinate-is-success"),
              title: app.$t("msgbox.subordinate"),
              persistent: true,
              actions: {
                false: app.$t("button.ok")
              }
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },

  // getAdminDataResource({ commit }, payload) {
  //   http.get(`/admin/src/${payload.source}`).then(response => {
  //     if (
  //       response &&
  //       (response.status == 200 || response.status == "success")
  //     ) {
  //       switch (payload.source) {
  //         //units,bagians,jabatans,karyawan,roles
  //         case "units":
  //           commit("SET_UNITS", response.data);
  //           break;
  //         case "bagians":
  //           commit("SET_BAGIANS", response.data);
  //           break;
  //         case "jabatans":
  //           commit("SET_JABATANS", response.data);
  //           break;
  //         case "karyawan":
  //           commit("SET_KARYAWANS", response.data);
  //           break;
  //         case "roles":
  //           commit("SET_ROLES", response.data);
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   });
  // },
  resetRecipientPeerUpline({ commit }) {
    commit("SET_MEMO_RECIPIENTS", undefined);
    commit("SET_DIR_SEVP", undefined);
    commit("SET_MY_PEERS", undefined);
    commit("SET_MY_UPLINES", undefined);
  },
  setMyPeers({ commit }, payload) {
    commit("SET_MY_PEERS", payload);
  },
  setDispositionRecipients({ commit }, payload) {
    commit('SET_DISPOSITION_RECIPIENTS', payload);
  },
  setBroadcastRecipients({ commit }, payload) {
    commit('SET_BROADCAST_RECIPIENTS', payload);
  },
  setUnits({ commit }, payload) {
    commit("SET_UNITS", payload);
  },
  setBagians({ commit }, payload) {
    commit("SET_BAGIANS", payload);
  },
  setTypes({ commit }, payload) {
    commit("SET_TYPES", payload);
  },
  setJabatans({ commit }, payload) {
    commit("SET_JABATANS", payload);
  },
  setKaryawans({ commit }, payload) {
    commit("SET_KARYAWANS", payload);
  },
  setRoles({ commit }, payload) {
    commit("SET_ROLES", payload);
  },
  setBawahanTersedia({ commit }, payload) {
    commit('SET_BAWAHAN_TERSEDIA', payload);
  },
  setBawahanTerpilih({ commit }, payload) {
    commit('SET_BAWAHAN_TERPILIH', payload);
  },
  setPenggantiTersedia({ commit }, payload) {
    commit('SET_PENGGANTI_TERSEDIA', payload);
  },
  setPenggantiTerpilih({ commit }, payload) {
    commit('SET_PENGGANTI_TERPILIH', payload);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}