<template>
  <v-app id="inspire" class="theme--light">
    <v-main>
      <v-container class="fill-height mb-2 d-flex justify-center" fluid>
        <div class="text-center">
          <div style="text-align: center">
            <v-img src="/assets/img/logo.png" class="auto__width"></v-img>
          </div>
          <v-overlay :value="axiosIsLoading" opacity=".4">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
            <div
              class="mt-5 text-larger"
              v-html="$t('loader.please-wait-the-link-processor-is-working')"
            ></div>
          </v-overlay>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { http } from "@/http/http";
export default {
  name: "link-page",
  data: () => ({
    linkresult: undefined,
    axiosIsLoading: true,
  }),
  created() {
    setTimeout(() => {
      return this.getUrl(this.$route.params.id);
    }, 1000);
  },
  methods: {
    getUrl(id) {
      if (!id) return;
      return new Promise((resolve) => {
        this.axiosIsLoading = true;
        http.get(`/link/check?id=${id}`).then((response) => {
          if (response.status != 200) return (this.axiosIsLoading = false);
          let result = response.data;
          if (result.memo_detail)
            this.$router.replace({ name: "inbox.detail", params: { id: id } });
          else if (result.disposition_detail && !result.revision_detail)
            this.$router.replace({
              name: "disposition-detail",
              params: { id: result.disposition_detail },
            });
          else if (result.revision_detail && !result.disposition_detail)
            this.$router.replace({
              name: "sent.detailmar",
              params: { id: id },
            });
          else if (result.revision_detail && result.disposition_detail) {
            //user sebagai penerima disposisi sekaligus terlibat dalam pembuatan memo
            //biasanya pada memo izin prinsip atau memo BOM yang didisposisikan kembali ke pengirimnya
            let route = this.$router.resolve({ path: `/memo/sent/${id}/mar/` });
            window.open(route.href, "_blank");
            this.$router.replace({
              name: "disposition-detail",
              params: { id: result.disposition_detail },
            });
          } else {
            this.$router.replace({
              name: "dashboard",
            });
            setTimeout(() => {
              this.$dialog.notify.warning(this.$t("error.data-not-found"), {
                position: "top-left",
                timeout: 5000,
              });
            }, 1000);
          }
          return resolve(response);
        });
      });
    },
  },
};
</script>
<style>
.auto__width {
  margin: 0 auto;
  max-width: 75% !important;
}
</style>