import store from "@/store";

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key]
    var B = b[key]
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  var yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}

export const getCurrentUser = () => {
  let currUser = null;
  try {
    // token = localStorage.getItem('token') != null ? localStorage.getItem('token') : null;
   currUser = store.state.user.currentUser;
  } catch (error) {
    console.error(">>>> src/utils/index.js : getCurrentUser -> error", error)
    currUser = null;
  }
  return currUser;
}

export const setCurrentUser = (token) => {
  try {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token))
    } else {
      localStorage.removeItem('token');
    }
  } catch (error) {
    console.error(">>>> src/utils/index.js : setCurrentUser -> error", error)
  }
}