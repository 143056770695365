<template>
  <v-card class="mb-7" :class="propClass">
    <v-toolbar flat>
      <slot name="left-toolbar"/>
      <v-spacer></v-spacer>
       <slot name="right-toolbar" />
    </v-toolbar>
    <v-divider></v-divider>
    <div class="pa-md-2 pa-xs-0">
      <slot/>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",

  props: {
    heading: {
      type: String,
      default: "",
    },
    propClass : {type : String}
  },
  data: () => ({}),

  methods: {},
};
</script>