/* eslint-disable no-console */
import { register } from 'register-service-worker';
import store from './store/index';

// if (process.env.NODE_ENV === 'production') {
register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.info(
      'App is being served from cache by a service worker.\n' +
      'For more details, visit https://goo.gl/AFskqB'
    )
  },
  registered() {
    console.info('Service worker has been registered.');
    // Routinely check for app updates by testing for a new service worker.
    // setInterval(() => {
    //   registration.update();
    // }, 1000 * 60 * 60); // hourly checks
  },
  cached() {
    console.info('Content has been cached for offline use.')
  },
  updatefound() {
    console.info('New content is downloading.')
  },
  updated() {
    console.info('New content is available; please refresh.');
    store.dispatch("setPleaseRefresh", true); //call vuex to refresh page
  },
  offline() {
    console.info('No internet connection found. App is running in offline mode.')
  },
  error(error) {
    console.error('Error during service worker registration:', error)
  }
})
// }
