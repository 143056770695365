import {
  mdiViewDashboardVariant,
  mdiThemeLightDark,
  mdiLogout,
  mdiAccountSettingsOutline,
  mdiViewQuilt,
  mdiPen,
  mdiTooltipEdit,
  mdiThumbsUpDown,
  mdiSendCheck,
  mdiEmailArrowRight,
  mdiEmailArrowRightOutline,
  mdiEmailArrowLeftOutline,
  mdiStepBackward,
  mdiEmailCheckOutline,
  mdiEmailCheck,
  mdiTextBoxRemoveOutline,
  mdiAccountArrowLeftOutline,
  mdiAccountCogOutline,
  mdiFileTree,
  mdiApplicationCog,
  mdiInboxArrowDown,
  mdiTableSearch,
  mdiHomeCity,
  mdiMailboxOpenUp,
  mdiSortAscending,
  mdiSortDescending,
  mdiAccountMultipleOutline,
  mdiRewind,
  mdiEyeOff,
  mdiAsterisk,
  mdiCheckCircleOutline,
  mdiSignalVariant,
  mdiPrinter,
  mdiPrinterEye,
  mdiContentSave,
  mdiArrowLeftThick,
  mdiArrowRightThick,
  mdiEye,
  mdiTimerSand,
  mdiArchiveArrowDownOutline,
  mdiAccountGroup,
  mdiElevatorUp,
  mdiFileSendOutline,
  mdiFileEditOutline,
  mdiTextBoxEdit,
  mdiFilterOffOutline,
  mdiDeleteAlertOutline,
  mdiContentSaveOutline,
  mdiSendLock,
  mdiTransfer,
  mdiSend,
  mdiMessageProcessingOutline,
  mdiDatabaseClockOutline,
  mdiMessageReplyTextOutline,
  mdiInboxArrowDownOutline,
  mdiShieldAccount,
  mdiContentSaveMoveOutline,
  mdiFilePdfBox,
  mdiPlaneCar,
} from "@mdi/js";

const iconList = {
  mdiViewDashboardVariant,
  mdiThemeLightDark,
  mdiLogout,
  mdiAccountSettingsOutline,
  mdiViewQuilt,
  mdiPen,
  mdiTooltipEdit,
  mdiThumbsUpDown,
  mdiEmailArrowRight,
  mdiEmailArrowRightOutline,
  mdiEmailArrowLeftOutline,
  mdiStepBackward,
  mdiEmailCheckOutline,
  mdiEmailCheck,
  mdiTextBoxRemoveOutline,
  mdiAccountArrowLeftOutline,
  mdiAccountCogOutline,
  mdiFileTree,
  mdiApplicationCog,
  mdiInboxArrowDown,
  mdiTableSearch,
  mdiHomeCity,
  mdiMailboxOpenUp,
  mdiSortAscending,
  mdiSortDescending,
  mdiAccountMultipleOutline,
  mdiRewind,
  mdiEyeOff,
  mdiAsterisk,
  mdiCheckCircleOutline,
  mdiSignalVariant,
  mdiPrinter,
  mdiPrinterEye,
  mdiContentSave,
  mdiArrowLeftThick,
  mdiArrowRightThick,
  mdiEye,
  mdiTimerSand,
  mdiArchiveArrowDownOutline,
  mdiAccountGroup,
  mdiElevatorUp,
  mdiFileSendOutline,
  mdiFileEditOutline,
  mdiTextBoxEdit,
  mdiFilterOffOutline,
  mdiDeleteAlertOutline,
  mdiContentSaveOutline,
  mdiSendCheck,
  mdiSendLock,
  mdiTransfer,
  mdiSend,
  mdiMessageProcessingOutline,
  mdiDatabaseClockOutline,
  mdiMessageReplyTextOutline,
  mdiInboxArrowDownOutline,
  mdiShieldAccount,
  mdiContentSaveMoveOutline,
  mdiFilePdfBox,
  mdiPlaneCar,
};

export default iconList;
