import { http } from "@/http/http";
import app from '@/main';
import { mdiCheck } from "@mdi/js";
import { rowPerPage } from "@/constants/config.js";

const state = {
  dispositionList: undefined,
  axiosLoading: false,
  dispositionLoading:false,
  singleDisposition: undefined,
  // multiDisposition: undefined,
  singleDelegation: undefined,
  singleMemo: null,
  singleFeedback: null,
  selectAllDisposition: false,
  dispositionTrack: undefined,
  dispositionFeedbacks: undefined,
  dipositionAlert: false,
  dispotisionAlertMessage: undefined,
  dispositionDialog: false,
  dispositionHistoryDialog: false,
  printPreviewDialog: false,
  delegationHistory: null,
  dispositionHistory: null,
  dispositionHistoryDetail: null,
  filterParams: {
    sortBy: "datetime",
    sortByDesc: "desc",
    perPage: rowPerPage,
    currentPage: 1,
    only: '',
    filter: '',
    from: 1,
    to: 50,
    lastPage: 1
  }
};
const getters = {
  dispositionList: state => state.dispositionList,
  axiosLoading: state => state.axiosLoading,
  // multiDisposition: state => state.multiDisposition,
  singleDisposition: state => state.singleDisposition,
  singleDelegation: state => state.singleDelegation,
  selectAllDisposition: state => state.selectAllDisposition,
  dispositionTrack: state => state.dispositionTrack,
  dispositionFeedbacks: state => state.dispositionFeedbacks,
  dipositionAlert: state => state.dipositionAlert,
  dispotisionAlertMessage: state => state.dispotisionAlertMessage,
  dispositionDialog: state => state.dispositionDialog,
  dispositionHistoryDialog: state => state.dispositionHistoryDialog,
  printPreviewDialog: state => state.printPreviewDialog,
  delegationHistory: state => state.delegationHistory,
  dispositionHistory: state => state.dispositionHistory,
  dispositionHistoryDetail: state => state.dispositionHistoryDetail
};
const mutations = {
  SET_DISPOSITION_LIST(state, payload) {
    state.dispositionList = payload;
    state.filterParams.perPage = payload.per_page;
    state.filterParams.currentPage = payload.current_page;
    state.filterParams.lastPage = payload.last_page;
    state.filterParams.filter = "";
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_FILTER_PARAMS(state, payload) {
    state.filterParams.sortBy = payload.sortBy;
    state.filterParams.sortByDesc = payload.sortByDesc
    state.filterParams.perPage = payload.perPage;
    state.filterParams.currentPage = payload.currentPage;
    state.filterParams.lastPage = payload.lastPage;
    state.filterParams.filter = "";
    state.filterParams.only = payload.only;
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload
  },
  SET_DISPOSITION_LOADING(state, payload) {
    state.dispositionLoading = payload
  },
  // SET_MULTI_DISPOSITION(state, payload) {
  //   state.multiDisposition = payload;
  // },
  SET_SINGLE_DISPOSITION(state, payload) {
    state.singleDisposition = payload;
  },
  SET_SINGLE_DELEGATION(state, payload) {
    state.singleDelegation = payload;
  },
  SET_SELECT_ALL_DISPOSITION(state, payload) {
    state.selectAllDisposition = payload;
  },
  SET_EMPTY_DISPOSITION(state) {
    state.dispositionList = undefined;
  },
  SET_SINGLE_MEMO(state, payload) {
    state.singleMemo = payload;
  },
  SET_SINGLE_FEEDBACK(state, payload) {
    state.singleFeedback = payload;
  },
  SET_DISPOSITION_HISTORY(state, payload) {
    state.dispositionHistory = payload;
  },
  SET_DISPOSITION_HISTORY_DETAIL(state, payload) {
    state.dispositionHistoryDetail = payload
  },
  SET_DISPOSITION_TRACK(state, payload) {
    state.dispositionTrack = payload
  },
  SET_DISPOSITION_FEEDBACKS(state, payload) {
    state.dispositionFeedbacks = payload
  },
  SET_DELEGATION_HISTORY(state, payload) {
    state.delegationHistory = payload;
  },
  SET_DISPOSITION_DIALOG(state, payload) {
    state.dispositionDialog = payload;
  },
  SET_DISPOSITION_HISTORY_DIALOG(state, payload) {
    state.dispositionHistoryDialog = payload;
  },
  SET_PRINT_PREVIEW_DIALOG(state, payload) {
    state.printPreviewDialog = payload;
  },
  SET_MEMO_VALIDATION_DIALOG(state, payload) {
    state.memoValidationDialog = payload;
  },
};
const actions = {
  loadDispositions({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/mydisposition?page=${payload.currentPage}&filter=${payload.filter}&only=${payload.only}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
      // http.get(`/memo/mydisposition?page=${payload.currentPage}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_DISPOSITION_LIST', response.data);
            dispatch('resources/getDataResource',{source: "counting", tipe:"all"}, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },
  getDispositionDetail({ commit, dispatch }, payload) {
    commit('SET_SINGLE_MEMO', undefined);
    commit('SET_SINGLE_DISPOSITION', undefined);
    commit('SET_DISPOSITION_LOADING', true);
    return new Promise((resolve) => {

      dispatch('getMemoDetail', payload);
      // dispatch('inbox/loadInboxHistory', payload.memoId);

      http.get(`/memo/mydisposition/${payload.dispositionId}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
            commit('SET_SINGLE_DISPOSITION', response.data)
          }
          setTimeout(() => {
            commit('SET_DISPOSITION_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },

  submitMyDisposition({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)
    //save, submit, share-to-revision, send, broadcast
    return new Promise((resolve) => {
      //submit is send to superior
      // api/memo/{id}/disposition
      http.post(`/memo/${payload.memoId}/disposition`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            // dispatch('loadInboxDetail', payload.memoId);

            dispatch('getDispositionDetail', { memoId: payload.dispositionId, dispositionId: payload.dispositionId });
            //perhatikan bahawa memoId dan dispositionId di atas sama memakai payload.dispositionId,
            //karena backend mengarapkan nilai yang sama utk
            //mengambil detail memo terkait disposition dan disposition detailnya sendiri.

            // dispatch('getDisposition', { memoId: payload.memoId, bgProcess: true, type: 'mydelegation' });

            app.$dialog.info({
              text: app.$t("msgbox.submit-disposition-to-downline-is-success"),
              title: app.$t("msgbox.submit-disposition"),
              persistent: true,
              actions: {
                false: app.$t("button.ok")
              }
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },

  sendFeedback({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)
    //save, submit, share-to-revision, send, broadcast
    return new Promise((resolve) => {
      //submit is send to superior
      // api/memo/{id}/disposition
      http.post(`/memo/mydisposition/${payload.dispositionId}/feedback`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
             dispatch('getDispositionDetail', { memoId: payload.dispositionId, dispositionId: payload.dispositionId });
            //perhatikan bahawa memoId dan dispositionId di atas sama memakai payload.dispositionId,
            //karena backend mengarapkan nilai yang sama utk
            //mengambil detail memo terkait disposition dan disposition detailnya sendiri.

            // dispatch('getDisposition', { memoId: payload.memoId, bgProcess: true, type: 'mydelegation' });

            app.$dialog.info({
              text: app.$t("msgbox.submit-feedback-is-success"),
              title: app.$t("msgbox.submit-feedback"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },
  getMemoDetail({ commit }, payload) {
    return new Promise((resolve) => {

      http.get(`/memo/mydisposition/${payload.memoId}/memo`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_MEMO', response.data)
          }
          return resolve(response.data)
        })
    })
  },
  getFeedback({ commit }, payload) {
    return new Promise((resolve) => {
      // api/memo/{id}/disposition/{disposition_id}/feedbacks
      http.get(`/memo/mydisposition/${payload.dispositionId}/memo`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_FEEDBACK', response.data)
          }
          return resolve(response.data)
        })
    })
  },
  getDispositionTrack({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      http.get(`/memo/${payload.memoId}/history/receiver?pagename=disposition`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_DISPOSITION_TRACK', response.data)
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          return resolve(response.data)
        });
    });
  },
  getDispositionFeedbacks({ commit }, payload) {
    // api/memo/{id}/disposition/{disposition_id}/feedbacks
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      http.get(`/memo/${payload.memoId}/disposition/${payload.dispositionId}/feedbacks`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_DISPOSITION_FEEDBACKS', response.data)
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          return resolve(response.data)
        });
    });
  },
  emptyDisposition({ commit }, payload) {
    commit('SET_EMPTY_DISPOSITION', payload)
  },
  setSelectAllDisposition({ commit }, payload) {
    commit('SET_SELECT_ALL_DISPOSITION', payload)
  },
  setFilterParams({ commit }, payload) {
    commit("SET_FILTER_PARAMS", payload);
  },
  setAxiosLoading({ commit }, payload) {
    commit("SET_AXIOS_LOADING", payload);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}