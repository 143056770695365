import { http } from "@/http/http";
import app from '@/main';
import { mdiCheck } from "@mdi/js";
// import { rowPerPage } from "@/constants/config.js";

const state = {
  institutionList: undefined,
  singleInstitution: undefined,
  axiosProcessing: false,
  savingProcess: false,
  savingSuccess: false,
};

const mutations = {
  setAxiosProcess(state, payload) {
    state.axiosProcessing = payload;
  },
  setSavingProcess(state, payload) {
    state.savingProcess = payload;
    if (payload === true) state.savingSuccess = false;
  },
  setSavingSuccess(state, payload) {
   state.savingSuccess = payload;
  },
  setInstitutionList(state, payload) {
    state.institutionList = payload;
  },
  setSingleInstitution(state, payload) {
    state.singleInstitution = payload;
  },
  
};
const actions = {
  getInstitutionList({ commit, state }, payload) {
    if (state.axiosProcessing === true) return;
    commit('setAxiosProcess', true);
    
    return new Promise(resolve => {
      http.get(`/admin/lembagas?nama=${payload ? payload :''}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("setInstitutionList", response.data);
          commit('setAxiosProcess', false);
        }
        else {
          commit('setAxiosProcess', false);
        }
        return resolve(response.data);
      });

      // commit('setAxiosProcess', false);
    });
  },
  getInstitutionDetail({commit, state}, payload){
    if (state.axiosProcessing === true) return;
    commit('setAxiosProcess', true);
    return new Promise(resolve => {
      http.get(`/admin/lembagas/${payload}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("setSingleInstitution", response.data);
          commit('setAxiosProcess', false);
        }  else {
          commit('setAxiosProcess', false);
        }
        return resolve(response.data);
      });
    });

  },
  saveInstitution({ commit, state, dispatch }, payload) {
    if (state.savingProcess === true) return;
    commit('setSavingProcess', true);
    return new Promise(resolve => {
      http.post(`/admin/lembagas`, payload).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {

          app.$dialog.info({
            text: app.$t("msgbox.saving new institution is success"),
            title: app.$t("msgbox.saving institution"),
            persistent: true,
            actions: [
              { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
            ]
          });

          dispatch('getInstitutionList');
          commit('setSavingSuccess', true);
          commit('setSavingProcess', false);

        } else {
          app.$dialog.error({
            text: app.$t('error.'+response.data.data),
            title: app.$t("msgbox.saving institution"),
            persistent: true,
            actions: {
              false: app.$t("button.ok")
            }
          });
          commit('setSavingProcess', false);
        }
        return resolve(response.data);
      });
    });
  },
  updateInstitution({ commit, state, dispatch }, payload) {
    if (state.savingProcess === true) return;
    commit('setSavingProcess', true);
    return new Promise(resolve => {
      http.post(`/admin/lembagas/${payload.id}`, payload.data).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {

          app.$dialog.info({
            text: app.$t("msgbox.update institution is success"),
            title: app.$t("msgbox.update institution"),
            persistent: true,
            actions: [
              { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
            ]
          });
          
          dispatch('getInstitutionList');
          commit('setSavingSuccess', true);
          commit('setSavingProcess', false);
          
        } else {
          app.$dialog.error({
            text: app.$t("error.update institution is failed"),
            title: app.$t("msgbox.update institution"),
            persistent: true,
            actions: {
              false: app.$t("button.ok")
            }
          });
          commit('setSavingProcess', false);
        }
        return resolve(response.data);
      });
    });
  },
  deleteInstitution({ commit, state, dispatch }, payload) {
    if (state.savingProcess === true) return;
    commit('setSavingProcess', true);
    return new Promise(resolve => {
      http.delete(`/admin/lembagas/${payload}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {

          app.$dialog.info({
            text: app.$t("msgbox.delete institution is success"),
            title: app.$t("msgbox.delete institution"),
            persistent: true,
            actions: [
              { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
            ]
          });

          dispatch('getInstitutionList');
          commit('setSavingSuccess', true);
          commit('setSavingProcess', false);

        } else {
          app.$dialog.error({
            text: app.$t("error.delete institution is failed"),
            title: app.$t("msgbox.delete institution"),
            persistent: true,
            actions: {
              false: app.$t("button.ok")
            }
          });
          commit('setSavingProcess', false);
        }
        return resolve(response.data);
      });

    });
  },
  setSavingSuccess({commit}, payload){
    commit('setSavingProcess', payload);
  },
  setAxiosProcess({commit}, payload){
    commit('setAxiosProcess', payload);
  }

};
const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}