import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import app from "../main";

import user from "./modules/user";
import resources from "./modules/resources";
import institution from "./modules/institution";
import inbox from "./modules/inbox";
import sent from "./modules/sent";
import revoked from "./modules/revoked";
import disposition from "./modules/disposition";
import draft from "./modules/draft";
import pdfgenerator from "./modules/pdfgenerator";
import terminated from "./modules/terminated";
import adminpanel from "./modules/adminpanel";
import { http } from "@/http/http";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "ELEMEN",
  strictMode: true,
  storage: window.localStorage,
  reducer: (state) => ({ user: state.user, resources: state.resources }),
});

const globalThemeIsDark =
  localStorage.getItem("currentThemeIsDark") &&
  localStorage.getItem("currentThemeIsDark") == "true"
    ? true
    : false;

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    networkError: false,
    backendError: undefined,
    currentLanguage: localStorage.getItem("currentLanguage")
      ? localStorage.getItem("currentLanguage")
      : "id",
    currentThemeIsDark: globalThemeIsDark,
    sidebarDrawer: true,
    Customizer_drawer: false,
    expandOnHover: false,
    SidebarColor: "dark",
    SidebarBg: "",
    isMobile: false,
    loaderMessage: null,
    appMessageState: false,
    createGeneralMemo: false,
    createXLetter: false,
    createPermitMemo: false,
    createIncomingLetter: false,
    createSppd: false,
    editSppd: {id:null, status:false},
    createCrashLetter: false,
    editCrashLetter: false,
    editIncomingLetter: false,
    createSubholdingMemo: false,
    createXNusantara: false,

    helpGroupRecipientDialog: false,
    tipToHide: null,
    appSnackbar: {
      show: false,
      type: "info",
      title: "",
      message: "",
    },
    appMessageBox: {
      title: "",
      message: "",
      type: "success",
      customCloseBtnText: "close",
      disableOverlayClick: true,
      useConfirmBtn: false,
      customConfirmBtnText: "Yes",
    },
    messageBoxReturnValue: null,
    pleaseRefresh: false,
    isAlive: undefined,
  },
  getters: {
    isMobile: (state) => state.isMobile,
    expandOnHover: (state) => state.expandOnHover,
    sidebarDrawer: (state) => state.sidebarDrawer,
    appMessageBox: (state) => state.appMessageBox,
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
    CHANGE_LANG(state, payload) {
      app.$i18n.locale = payload;
      state.currentLanguage = payload;
      localStorage.setItem("currentLanguage", payload);
    },
    CHANGE_THEME_DARK(state, payload) {
      app.$vuetify.theme.dark = payload;
      state.currentThemeIsDark = payload;
      localStorage.setItem("currentThemeIsDark", payload);
    },
    CHANGE_LOCAL_THEME_DARK(state, payload) {
      app.$vuetify.theme.dark = payload;
      state.currentThemeIsDark = payload;
    },
    SET_SIDEBAR_DRAWER(state, payload) {
      state.sidebarDrawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_DEVICE_IS_MOBILE(state, payload) {
      state.isMobile = payload;
      if (payload === true) {
        state.expandOnHover = false;
      }
    },
    SET_EXPAND_ON_HOVER(state, payload) {
      state.expandOnHover = payload;
    },
    CLEAR_APP_MESSAGE_BOX(state) {
      state.appMessageState = false;
    },
    SET_APP_MESSAGE_BOX_STATE(state, payload) {
      state.appMessageState = payload;
    },
    SET_APP_MESSAGE_BOX(state, payload) {
      state.appMessageBox = payload;
    },
    SET_MESSAGE_BOX_RETURN_VALUE(state, payload) {
      state.messageBoxReturnValue = payload;
    },
    SET_APP_SNACKBAR(state, payload) {
      state.appSnackbar = payload;
    },
    SET_APP_SNACKBAR_STATE(state, payload) {
      state.appSnackbar.show = payload;
    },
    SET_CREATE_GENERAL_MEMO(state, payload) {
      state.createGeneralMemo = payload;
    },
    SET_CREATE_SPPD(state, payload) {
      state.createSppd = payload;
    },
    SET_EDIT_SPPD(state, payload) {
      state.editSppd = payload;
    },
    SET_CREATE_PERMIT_MEMO(state, payload) {
      state.createPermitMemo = payload;
    },
    SET_CREATE_CRASH_LETTER(state, payload) {
      state.createCrashLetter = payload;
    },
    SET_EDIT_CRASH_LETTER(state, payload) {
      state.editCrashLetter = payload;
    },
    SET_CREATE_INCOMING_LETTER(state, payload) {
      state.createIncomingLetter = payload;
    },
    SET_EDIT_INCOMING_LETTER(state, payload) {
      state.editIncomingLetter = payload;
    },
    SET_CREATE_X_LETTER(state, payload) {
      state.createXLetter = payload;
    },
    SET_CREATE_SUBHOLDING_MEMO(state, payload) {
      state.createSubholdingMemo = payload;
    },
    SET_CREATE_X_NUSANTARA(state, payload) {
      state.createXNusantara = payload;
    },
    SET_NETWORK_ERROR(state, payload) {
      state.networkError = payload;
    },
    SET_BACKEND_ERROR(state, payload) {
      state.backendError = payload;
    },
    SET_HELP_GROUP_RECIPIENT_DIALOG(state, payload) {
      state.helpGroupRecipientDialog = payload;
      if (payload == false) state.tipToHide = false;
    },
    SET_TIP_TO_HIDE(state, payload) {
      state.tipToHide = payload;
    },
    SET_PLEASE_REFRESH(state, payload) {
      state.pleaseRefresh = payload;
    },
    SET_ALIVE(state, payload) {
      state.isAlive = payload;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("CHANGE_LANG", payload);
    },
    setThemeDark({ commit }, payload) {
      commit("CHANGE_THEME_DARK", payload);
    },
    setSidebarDrawer({ commit }, payload) {
      commit("SET_SIDEBAR_DRAWER", payload);
    },
    setDeviceIsMobile({ commit }, payload) {
      commit("SET_DEVICE_IS_MOBILE", payload);
    },
    setExpandOnHover({ commit }, payload) {
      commit("SET_EXPAND_ON_HOVER", payload);
    },
    incDecProcess({ commit }, payload) {
      commit("INC_DEC_PROCESS", payload);
    },
    setMessageBox({ commit }, payload) {
      if (payload.snackbar === true) {
        commit("SET_APP_SNACKBAR", payload);
      } else {
        commit("SET_APP_MESSAGE_BOX", payload.msgBoxParams);
        commit("SET_APP_MESSAGE_BOX_STATE", payload.show);
      }
    },
    messageBoxReturnValue({ commit }, payload) {
      commit("SET_MESSAGE_BOX_RETURN_VALUE", payload);
    },
    clearAllError({ commit }, payload) {
      commit("SET_APP_SNACKBAR", payload);
      commit("SET_APP_MESSAGE_BOX", payload);
    },
    setCreateGeneralMemo({ commit }, payload) {
      commit("SET_CREATE_GENERAL_MEMO", payload);
    },
    setCreateXLetter({ commit }, payload) {
      commit("SET_CREATE_X_LETTER", payload);
    },
    setCreateSubholdingMemo({ commit }, payload) {
      commit("SET_CREATE_SUBHOLDING_MEMO", payload);
    },
    setCreateXNusantara({ commit }, payload) {
      commit("SET_CREATE_X_NUSANTARA", payload);
    },
    setCreateSppd({ commit }, payload) {
      commit("SET_CREATE_SPPD", payload);
    },
    setEditSppd({ commit }, payload) {
      commit("SET_EDIT_SPPD", payload);
    },
    setCreatePermitMemo({ commit }, payload) {
      commit("SET_CREATE_PERMIT_MEMO", payload);
    },
    setCreateIncomingLetter({ commit }, payload) {
      commit("SET_CREATE_INCOMING_LETTER", payload);
    },
    setCreateCrashLetter({ commit }, payload) {
      commit("SET_CREATE_CRASH_LETTER", payload);
    },
    setNetworkError({ commit }, payload) {
      commit("SET_NETWORK_ERROR", payload);
    },
    setBackendError({ commit }, payload) {
      commit("SET_BACKEND_ERROR", payload);
    },
    setHelpGroupRecipientDialog({ commit }, payload) {
      commit("SET_HELP_GROUP_RECIPIENT_DIALOG", true);
      commit("SET_TIP_TO_HIDE", payload);
    },
    setPleaseRefresh({ commit }, payload) {
      //fungsi untuk notifikasi Service Worker punya file baru
      commit("SET_PLEASE_REFRESH", payload);
    },
    tokenKeepAlive({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http.post("/api/keep-alive").then((response) => {
          if (response !== true) {
            return reject(response);
          }
          commit("SET_ALIVE", payload);
          return resolve(response);
        });
      });
    },
  },
  modules: {
    user,
    resources,
    institution,
    inbox,
    sent,
    revoked,
    disposition,
    draft,
    pdfgenerator,
    terminated,
    adminpanel,
  },
});
