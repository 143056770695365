import Vue from 'vue';
import Vuetify, { VHover } from 'vuetify/lib/framework';
import '@/assets/scss/vuetify/_overrides.scss';
Vue.use(Vuetify,
  {
    components: { VHover },
  }
  );

const themeLight = {
    primary: '#008ecc',
    secondary:'#EF7E5C',
    info: '#1e88e5',
    success: '#21c1d6',
    accent: '#DE615E',
    default: '#DE615E'
}

const themeDark = {
    primary: '#008ecc',
    secondary:'#EF7E5C',
    info: '#1e88e5',
    success: '#21c1d6',
    accent: '#DE615E',
    default: '#DE615E'
}

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
    theme: {
        themes: {
          dark: themeDark,
          light: themeLight,
        },
      }
});
